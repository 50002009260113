import React from "react";
import { View } from '@aws-amplify/ui-react';
import { ReactComponent as MainLogo } from '../images/logo.svg';

const Logo = () => (
  <View padding={32}>
    <MainLogo className="main-logo" />
  </View>
);

export default Logo;
