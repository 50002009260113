import { Theme } from '@aws-amplify/ui-react';

const colors = {
  white: "#ffffff",
  offwhite: "#aaaaaa",
  black: "#000000",
  background: "#1e2731",
  backgroundSecondary: "#32404f",
  teal: "#008080",
  tealHover: "#008b8b",
  yellow: "#ffff00",
  neutral: "#808080",
  blue: "#add8e6",
  blueHover: "#b0e0e6",
  red: "#ff6666",
};

const customTheme: Theme = {
  name: 'custom',
  tokens: {
    colors: {
      background: {
        primary: {
          value: colors.background,
        },
        secondary: {
          value: colors.backgroundSecondary,
        },
      },
      font: {
        interactive: {
          value: colors.blue,
        },
      },
    },
    components: {
      autocomplete: {
        menu: {
          backgroundColor: {
            value: colors.backgroundSecondary,
          },
          option: {
            color: {
              value: colors.white,
            },
            _active: {
              color: {
                value: colors.white,
              },
            },
          },
        },
      },
      heading: {
        color: {
          value: colors.white,
        },
      },
      text: {
        color: {
          value: colors.offwhite,
        },
        error: {
          color: {
            value: colors.red,
          },
        },
      },
      button: {
        primary: {
          backgroundColor: { value: colors.teal },
          _hover: {
            backgroundColor: { value: colors.tealHover },
          },
          _loading: {
            backgroundColor: { value: colors.neutral },
          },
          _disabled: {
            backgroundColor: { value: colors.neutral },
          },
        },
        color: {
          value: colors.white,
        },
      },
      field: {
        label: {
          color: {
            value: colors.white,
          },
        },
      },
      fieldcontrol: {
        color: {
          value: colors.white,
        },
        _error: {
          borderColor: {
            value: colors.red,
          },
        },
      },
      fieldmessages: {
        error: {
          color: {
            value: colors.red,
          },
        },
      },
      tabs: {
        item: {
          color: {
            value: colors.offwhite,
          },
          _focus: {
            color: {
              value: colors.white,
            },
          },
          _hover: {
            color: {
              value: colors.blue,
            },
          },
          _active: {
            color: {
              value: colors.white,
            },
          },
        },
      },
    },
  },
};

export default customTheme;
