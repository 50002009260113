import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoadScript } from '@react-google-maps/api';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify, Auth, I18n } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import {
  Authenticator,
  translations,
  ThemeProvider,
  Loader,
} from '@aws-amplify/ui-react';
import awsconfig from './aws-exports.js';
import Theme from './theme/global';
import Logo from './components/Logo';

I18n.putVocabularies(translations);
I18n.setLanguage('pt');
Amplify.configure(awsconfig);
Auth.configure(awsconfig);
const queryClient = new QueryClient();

const Map = lazy(() => import('./components/Map'));
const PinForm = lazy(() => import('./components/PinForm'));
const Room = lazy(() => import('./components/Room'));

const App: React.FC = () => (
  <ThemeProvider theme={Theme} colorMode="dark">
    <Authenticator
      variation="modal"
      components={{
        Header() {
          return <Logo />;
        },
      }}
    >
      <LoadScript
        id="google-map"
        googleMapsApiKey={process.env.REACT_APP_GMAPS_KEY || ''}
        language='pt'
        region='BR'
        loadingElement={<Loader className="loader-fixed" />}
        libraries={['places', 'geocoding']}
      >
        <QueryClientProvider client={queryClient}>
          <Router>
            <Suspense fallback={<Loader className="loader-fixed" />}>
              <Routes>
                <Route path="/" element={<Map />} />
                <Route path="/pin/:roomID" element={<Room />} />
                <Route path="/add-pin" element={<PinForm create />} />
              </Routes>
            </Suspense>
          </Router>
        </QueryClientProvider>
      </LoadScript>
    </Authenticator>
  </ThemeProvider>
);

export default App;
